<template>
  <app-layout :title="$t('metaTitleHome')" languageSupport='laravel' :languageOvr='language'>
    <template v-slot:header>
    	<div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center" id="top_area">
        <!--Left Col-->
        <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <h1 v-html="$t('homepageSlogan')" />
          <p class="leading-normal text-2xl mb-8" v-html="$t('homepageSubSlogan')" />
          <button name="buttonTop_2minutes" class="buttonTransp text-2xl" @click="go2createPage" v-html="$t('createIn2Mins')" />
        </div>
        <!--Right Col-->
        <div class="w-full md:w-3/5 py-6 text-center">
          <picture class="w-full md:w-4/5 z-50">
            <source srcset="/images/landing.webp" type="image/webp">
            <source srcset="/images/landing.png" type="image/jpeg">
            <img src="/images/landing.png" :alt="$t('riseBalloons')">
          </picture>
        </div>
      </div>
    </template>

    <div class="container max-w-5xl mx-auto m-8">
      <homepage-box-text-right id="explanation_Video" :header="$t('whatsBalloco')" :images="[{name: 'home/landing_expl_video', alt: $t('fromVideo')}]">
        {{ $t('whatsBallocoText') }} <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.youtube.com/watch?v=ba_HrWxr9W4" v-html="$t('ourYoutubeVideo')" />.
        <br>
        <div class="text-xs mt-1">
          <i18n-t keypath="disclaimerYoutube" tag="span">
            <template #youtubeLink>
              <external-link url='https://policies.google.com/?hl=de' :text="$t('privacyPolicyYoutube')" />
            </template>
          </i18n-t>
        </div>
      </homepage-box-text-right>

      <homepage-box-text-left id="explanation_Gruesse_multimedial" :header="$t('greetingsMultimedia')" :images="[
        {name: 'scan/scan_' + language, alt: $t('greetingsMultimedia'), link: true},
        {name: 'home/landing_expl_1', alt: $t('selfieWedding')}
      ]">
        {{ $t('greetingsMultimediaText1') }} (<a id="linkDemoGreeting" :href="urlDemoGreeting" v-html="$t('tryOutHere')" />). {{ $t('greetingsMultimediaText2') }}
      </homepage-box-text-left>

      <homepage-box-text-right id="explanation_Digital_zurueckschicken" :header="$t('sendBackDigitally')" :images="[{name: 'home/landing_expl_2b', alt: $t('womanScanningQR')}]">
        {{ $t('sendBackDigitallyText') }} (<a id="linkDemoFound" :href="urlDemoFound" v-html="$t('tryOutHere')" />).
      </homepage-box-text-right>

      <homepage-box-text-left id="explanation_Keine_verlorenen_Gruesse" :header="$t('noLostGreetings')" :images="[{name: 'home/landing_expl_2c', alt: $t('bridesmaids')}]">
        <span v-html="$t('noLostGreetingsText')" />
      </homepage-box-text-left>

      <homepage-box-text-right :dots="false" id="explanation_Sofort_ausdrucken" :header="$t('printAsYouWant')" :images="[
        {name: 'cards/cards_std_VIN_' + language + '_front', alt: $t('cards'), link: true},
        {name: 'cards/cards_std_VIN_' + language + '_back', alt: $t('cards'), link: true},
        {name: 'cards/cards_std_WHT_' + language + '_front', alt: $t('cards'), link: true},
        {name: 'cards/cards_std_WHT_' + language + '_back', alt: $t('cards'), link: true},
        {name: 'labels/labels_' + language, alt: $t('labels'), link: true}
      ]">
        <span v-html="$t('printAsYouWantText')" />
      </homepage-box-text-right>

      <homepage-box-text-left id="explanation_let_print" :header="$t('orLetPrint')" :images="[{name: 'home/landing_expl_7', alt: $t('delivery')}]">
        <span v-html="$t('orLetPrintText1')" /> ➤ <Link href="pricing">{{ $t('pricinginfo') }}</Link><br>
        <span v-html="$t('labelsIfOwnUse')" /> ➤ <Link href="pricing">{{ $t('pricinginfo') }}</Link><br>
        <div class="text-xs mt-1"><span v-html="$t('orderWhenCreated')" /> <a href="javascript:void(0)" @click="go2createPage" name="link_fromCardPrint" v-html="$t('haveCreated')" />.</div>
      </homepage-box-text-left>

      <homepage-box-text-right id="explanation_Sicherheit_Chiffre" :header="$t('securityChiffre')" :images="[{name: 'home/landing_expl_3', alt: $t('burglar')}]">
        <span v-html="$t('securityChiffreText')" />
      </homepage-box-text-right>

      <homepage-box-text-left id="explanation_Plastikneutral" :header="$t('plasticNeutral')" :images="[
        {name: 'home/landing_expl_4a', alt: $t('plasticFreePlanet')},
        {name: 'home/landing_expl_4b', alt: $t('plasticFreePlanet'), link: true},
      ]">
        <i18n-t keypath="plasticNeutralText" tag="span">
          <template #plasticLink>
            <external-link text='Plastic Free Planet' url='https://plastic-free-planet.org' />
          </template>
        </i18n-t>
      </homepage-box-text-left>

      <homepage-box-text-right id="explanation_TrauzeugIn" :header="$t('bestManOrGuest')" :images="[{name: 'home/landing_expl_5', alt: $t('bridesmaids')}]">
        <span v-html="$t('bestManOrGuestText')" />
      </homepage-box-text-right>

      <homepage-box-text-left id="explanation_Giftcard" :header="$t('giftcardHomepage')" :images="[
        {name: 'giftcard/giftbox_all', alt: $t('giftcardHomepage'), link: true},
        {name: 'giftcard/giftcard', alt: $t('giftcardHomepage'), link: true},
        {name: 'book/closed', alt: $t('photobook'), link: true},
        {name: 'book/open1', alt: $t('photobook'), link: true},
        {name: 'book/open2', alt: $t('photobook'), link: true},
        {name: 'book/pouches', alt: $t('photobook'), link: true}
      ]">
        <span v-html="$t('giftcardHomepageText')" />
        ➤ <Link href="pricing">{{ $t('pricinginfo') }}</Link>
        <div class="text-xs mt-1"><span v-html="$t('orderWhenCreated')" /> <a href="javascript:void(0)" @click="go2createPage" name="link_fromCardPrint" v-html="$t('haveCreated')" />.</div>
      </homepage-box-text-left>

      <homepage-box-text-right id="explanation_Map" :header="$t('findingLocationsPic')" :images="[{name: 'home/landing_expl_6', alt: $t('findingLocationsExample')}]">
        <span v-html="$t('findingLocationsText')" />
      </homepage-box-text-right>

      <homepage-box-text-left :dots="false" id="explanation_Custom" :header="$t('competition')" :images="[
        {name: 'home/landing_expl_custom', alt: $t('balloonRelease'), link: true},
        {name: 'cards/cards_competition_VIN_' + language + '_front', alt: $t('cards'), link: true},
        {name: 'cards/cards_competition_VIN_' + language + '_back', alt: $t('cards'), link: true},
        {name: 'cards/cards_competition_ROS_' + language + '_front', alt: $t('cards'), link: true},
        {name: 'cards/cards_competition_ROS_' + language + '_back', alt: $t('cards'), link: true}
      ]">
        <i18n-t keypath="competitionsText" tag="span">
          <template #demoGreetingPublicLink>
            <a :href="urlDemoGreetingPublic" id="linkDemoGreetingPublic" v-html="$t('exampleDigitalParticipation')" />
          </template>
          <template #demoFoundPublicLink>
            <a :href="urlDemoFoundPublic" id="linkDemoFoundPublic" v-html="$t('exampleDigitalReturn')" />
          </template>
        </i18n-t>
        <div class="text-xs mt-1">
          <i18n-t keypath="specialRequests" tag="span">
            <template #contactLink>
              <Link href="/contact">{{ $t('contactLinkArg') }}</Link>
            </template>
          </i18n-t>
        </div>
      </homepage-box-text-left>

      <homepage-box-text-right :dots="false" id="explanation_Cardbox" :header="$t('withoutBalloons')" :images="[
        {name: 'home/landing_expl_8', alt: $t('boxforcards')},
        {name: 'cards/cards_box_VIN_' + language + '_front', alt: $t('boxforcards'), link: true},
        {name: 'cards/cards_box_VIN_' + language + '_back', alt: $t('boxforcards'), link: true},
        {name: 'cards/cards_box_GRN_' + language + '_front', alt: $t('boxforcards'), link: true},
        {name: 'cards/cards_box_GRN_' + language + '_back', alt: $t('boxforcards'), link: true}
      ]">
        <span v-html="$t('withoutBalloonsText')" />
      </homepage-box-text-right>

    </div>

    <div v-if="language=='de'" class="container max-w-5xl mx-auto mb-8">
      <div class="px-6">
        <a href="https://jgashirt24.de/?utm_source=balloco&utm_medium=banner&utm_campaign=10-prozent-rabatt" target="_blank">
          <lazy-component>
            <ad-wrapper>
              <picture class="object-fill">
                <source srcset="/images/jgashirt24.webp 912w, /images/jgashirt24-600w.webp 600w, /images/jgashirt24-400w.webp 400w, /images/jgashirt24-300w.webp 300w" type="image/webp">
                <source srcset="/images/jgashirt24.jpg 912w, /images/jgashirt24-600w.jpg 600w, /images/jgashirt24-400w.jpg 400w, /images/jgashirt24-300w.jpg 300w" type="image/jpeg">
                <img src="/images/jgashirt24.jpg" alt="JGAShirt24" class="affiliateImg">
              </picture>
            </ad-wrapper>
          </lazy-component>
          </a>
      </div>
    </div>

    <big-buttons />
    <badges v-if="language=='de'" class="pt-6" />

    <div class="container mx-auto flex flex-wrap pb-12" id="orderDiv">
      <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">{{ $t('createCardsNow') }}</h1>
      <div class="w-full mb-4">
        <div class="horline"></div>
      </div>
      <card-request-form />
    </div>

    <div class="container mx-auto flex flex-wrap pb-12" id="newsletterDiv">
      <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">{{ $t('registerNewsletter') }}</h1>
      <div class="w-full mb-4">
        <div class="horline"></div>
      </div>
      <newsletter-form />
    </div>

    <template v-slot:footer>
    </template>
  </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import CardRequestForm from '@/Shared/CardRequestForm.vue'
import NewsletterForm from '@/Shared/NewsletterForm.vue'
import HomepageBoxTextLeft from '@/Shared/HomepageBoxTextLeft.vue'
import HomepageBoxTextRight from '@/Shared/HomepageBoxTextRight.vue'
import AdWrapper from '@/Shared/AdWrapper.vue'
import Badges from '@/Shared/Badges.vue'
import BigButtons from '@/Shared/BigButtons.vue'
import { Link } from '@inertiajs/vue3';
import ExternalLink from '@/Shared/ExternalLink.vue'

export default
{
  props: ['urlDemoGreeting', 'urlDemoFound', 'urlDemoGreetingPublic', 'urlDemoFoundPublic', 'language'],
  data() {
    return {
      scrolledTo: []
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  components:
  {
    AppLayout,
    CardRequestForm,
    NewsletterForm,
    HomepageBoxTextLeft,
    HomepageBoxTextRight,
    AdWrapper,
    Badges,
    BigButtons,
    Link,
    ExternalLink
  },
  methods:
  {
    handleScroll (event) {
      if (typeof gtag !== 'undefined') {
        var idsToCheck = ['top_area',
                          'explanation_Video',
                          'explanation_Gruesse_multimedial',
                          'explanation_Digital_zurueckschicken',
                          'explanation_Keine_verlorenen_Gruesse',
                          'explanation_Sofort_ausdrucken',
                          'explanation_let_print',
                          'explanation_Sicherheit_Chiffre',
                          'explanation_Plastikneutral',
                          'explanation_TrauzeugIn',
                          'explanation_Giftcard',
                          'explanation_Map',
                          'explanation_Custom',
                          'explanation_Cardbox',
                          'Hochzeitsportal24',
                          'orderDiv',
                          'newsletterDiv',
                          'adPlaceholder'
                          ];
        var self = this;
        idsToCheck.forEach(function(id) {
          let element = document.getElementById(id);
          if (element)
          {
            let bottom = element.getBoundingClientRect().bottom;
            if ((!(id in self.scrolledTo)) && (bottom <= (window.innerHeight || document.documentElement.clientHeight)))
            {
              self.scrolledTo[id] = true;
              gtag( 'event', 'scroll_after_' + id);
            }
          }
        });
      }
    },
    go2createPage(e)
    {
      location.href='/create';
    }
  },
}
</script>
